<template>
  <div class="data-table">
    <!-- SPINNER -->
    <div
      class="d-flex justify-content-center align-items-center spinner-container"
      v-if="isLoading"
    >
      <img src="@/assets/gif/spinner.gif" width="200" height="200" />
    </div>
    <div v-else>
      <DataTable
        :value="data"
        :paginator="false"
        class="data-table__table"
        :rows="10"
        dataKey="id"
        :rowHover="true"
        responsiveLayout="scroll"
        :selection.sync="selectedCustomers"
      >
        <template #header>
          <div class="data-table__header">
            <!--<div>
              <b-button
                class="data-table__header--button"
                >Send Quote</b-button
              >
            </div>
            <div class="data-table__container-filter">
              <b-input-group>
                  <b-form-input placeholder="Type to search"></b-form-input>
                  <b-input-group-append>
                  <b-button variant="info">
                      <b-icon icon="search" aria-hidden="true"></b-icon>
                  </b-button>
                  </b-input-group-append>
              </b-input-group>
            </div> -->
          </div>
        </template>
        <template #empty> No files found. </template>
        <!--<Column selectionMode="multiple"> </Column>-->
        <Column field="username" header="Created By">
          <template #body="{ data }">
            <p>{{ data.username }}</p>
          </template>
        </Column>
        <Column field="customer" header="Customer">
          <template #body="{ data }">
            <p>{{ data.customer.name }}</p>
          </template>
        </Column>
        <Column field="subject" header="Subject">
          <template #body="{ data }">
            <p>{{ data.subject }}</p>
          </template>
        </Column>
        <Column field="email" header="Email">
          <template #body="{ data }">
            <p v-for="(mail, index) in data.notify_to" :key="index">
              {{ mail }}
            </p>
          </template>
        </Column>
        <Column field="date" header="Created at">
          <template #body="{ data }">
            <p>{{ data.createdAt | formatDate }}</p>
          </template>
        </Column>
        <Column field="priority" header="Priority">
          <template #body="{ data }">
            <p>{{ data.priority }}</p>
          </template>
        </Column>
        <Column field="status" header="Status">
          <template #body="{ data }">
            <p>{{ data.status }}</p>
            <el-popover
            placement="top"
            trigger="hover"
            :title="totalHauls(data)">
            <el-progress
            slot="reference"
            :percentage="getCompletionPercentage(data)"
            :format="format">
            </el-progress>
          </el-popover>
          </template>
        </Column>
        <Column field="createOn" header="Create or not on reve">
          <template #body="{ data }">
            <p>{{ data.create_load ? "CREATED ON REVENOVA" : "NOT CREATED ON REVENOVA" }}</p>
          </template>
        </Column>
        <Column header="Download Scale">
          <template #body="{ data }">
            <div class="data-table__actions" v-if="data.status === 'Finished'">
              <span @click="downloadQuote(data.file_path)">
                <b-icon
                  icon="cloud-arrow-down-fill"
                  aria-hidden="true"
                  font-scale="2"
                  variant="info"
                ></b-icon>
              </span>
            </div>
          </template>
        </Column>
        <Column header="Cancel Scale">
          <template #body="{ data }">
            <div class="data-table__actions" v-if="data.status === 'Pending'
            || data.status === 'In process' || data.status === 'Quoting'
            || data.status === 'Generating excel'">
              <span @click="cancelQuote(data._id)">
                <b-icon
                  icon="file-earmark-excel-fill"
                  aria-hidden="true"
                  font-scale="2"
                  variant="danger"
                ></b-icon>
              </span>
            </div>
          </template>
        </Column>
      </DataTable>
      <b-pagination
        v-model="currentPage"
        pills
        :total-rows="totalItemsCount"
        :per-page="perPage"
        class="data-table__paginator"
        @change="paginate"
        aria-controls="data"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Table',
  data() {
    return {
      data: [],
      currentPage: 1,
      totalItemsCount: 0,
      perPage: 10,
      selectedCustomers: null,
      isLoading: false,
    };
  },
  async created() {
    this.getInfo();
  },
  methods: {
    format(percentage) {
      return percentage === 100 ? 'Full' : `${percentage.toFixed(2)}%`;
    },
    getCompletionPercentage(row) {
      const totalHauls = row.total_loads;
      const completedHauls = row.complete_loads || 0;
      if (totalHauls === 0) {
        return 0; // Return 0 if there are no hauls
      }
      const percentage = Math.min(Math.max((completedHauls / totalHauls) * 100, 0), 100);
      return percentage;
    },
    totalHauls(row) {
      const totalHauls = row.total_loads;
      const completeHauls = row.complete_loads;
      return `Total Hauls: ${totalHauls} - Complete Loads: ${completeHauls}`;
    },
    async paginate(page) {
      this.currentPage = page;
      this.getInfo();
      window.scrollTo(0, 0);
    },
    async getInfo() {
      this.isLoading = true;
      const params = {
        limit: 10,
        skip: (this.currentPage - 1) * 10,
      };
      const response = await this.$store.dispatch('scale/getQuoteHistory', params);
      this.data = response.data;
      this.totalItemsCount = response.count;
      this.isLoading = false;
      this.data.forEach((row) => {
        const priority = row.priority.replaceAll('_', ' ');
        // eslint-disable-next-line no-param-reassign
        row.priority = priority[0].toUpperCase() + priority.substring(1);
      });
    },
    downloadQuote(path) {
      const link = document.createElement('a');
      link.href = path;
      link.download = 'Template-Scales-V2';
      link.click();
    },
    async cancelQuote(path) {
      const result = await this.$swal({
        title: 'Are you sure?',
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Yes, accept',
        confirmButtonColor: '#14a2b8',
        showCancelButton: true,
        cancelButtonText: 'No, accept',
        cancelButtonColor: '#e53935',
      });
      if (result.isConfirmed) {
        const response = await this.$store.dispatch('scale/cancelScale', path);
        if (response.message === 'Cancel scale successfully') {
          this.data = this.data.map((item) => {
            // eslint-disable-next-line dot-notation
            if (item['_id'] === path && (item.status === 'in process' || item.status === 'pending')) {
              return { ...item, status: 'cancelled' };
            }
            return item;
          });
          await this.$swal('Ready!', 'The scale has been successfully cancelled.', 'success');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.data-table {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__table {
    font-size: 13.5px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    &--button {
      @include primary-button;
    }
  }
  &__actions {
    cursor: pointer;
  }
  &__paginator {
    position: relative;
    margin: 2rem 0 8rem;
    z-index: 0;
    justify-content: center;
  }
}
:deep .el-progress-bar{
width: 10rem;
}
:deep(.p-datatable-header) {
  background-color: $color-background-dark;
}
:deep(.p-datatable) {
  width: 96%;
}
:deep(.p-column-header-content) {
  justify-content: center;
}
:deep(.p-datatable-tbody > tr > td) {
  text-align: center !important;
}
:deep(.p-datatable .p-datatable-thead > tr > th) {
  text-align: center !important;
}
</style>
